<template>
  <div class="store-name">
    <div class="my-account">
      <div class="content clearfix">
        <div class="template-menu">
          <div class="menu">
            <div class="mar-menu">
              <ul>
                <li @click="choiceMenus(0)">
                  <div class="list acitve">
                    <a class="acitve">Store name</a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="file-title">
            <a-icon
              type="arrow-left"
              style="cursor: pointer;"
              @click="() => $router.go(-1)"
            /><span>Store setup</span>
          </div>
          <div class="store-con">
            <div class="store-left">
              <img src="../../assets/img/store.svg">
            </div>
            <div class="store-right">
              <div class="title">Store name</div>
              <!-- <div class="desc">
                Your store name will be shown on the ship from field on shipping
                labels
              </div> -->
              <a-input
                v-model="shopName"
                size="large"
                type="text"
                autocomplete="off"
              />
              <div class="btns">
                <a-button>Cancel</a-button>
                <a-button type="primary" @click="save">Save</a-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { setShopName } from '@/api/woocom'
export default {

  data() {
    return {
      shopName: 'name',
      shopId: ''
    }
  },
  mounted() {
    this.shopName = this.$route.query.name
    this.shopId = this.$route.query.shopId
  },
  methods: {
    async save() {
      const { code, msg } = await setShopName({
        shopId: this.$route.query.shopId,
        shopName: this.shopName
      })
      if (code === 200) {
        this.$message.success('Store settings saved')
      } else {
        this.$message.error(msg)
      }
    }
  }
}
</script>
<style scoped lang="scss">
.file-title {
  font-family: CerebriSans, sans-serif;
  font-weight: 500;
  line-height: 1.19;
  font-size: 33px;
  letter-spacing: -1px;
  margin: 20px 0;
  span {
    margin-left: 15px;
  }
}
.store-con {
  width: 884px;
  min-height: 50vh;
  background: white;
  padding: 16px;
  display: flex;
  .store-right {
    margin-left: 30px;
    flex: 1;
  }
  .title {
    margin-bottom: 24px;
    margin-top: 16px;
    font-size: 19px;
    font-weight: bold;
  }
  .desc {
    font-size: 13px;
  }
  input {
    margin-top: 20px;
  }
  .btns {
    text-align: right;
    margin-top: 25px;
    button {
      margin-left: 25px;
    }
  }
}
.template-menu {
  width: 240px;
}
.template-menu .menu {
  width: 100%;
  vertical-align: top;
  padding: 16px;
  font-size: 1rem;
  position: relative;
  margin-bottom: 16px;
  display: inline-block;
}
.template-menu .mar-menu {
  margin-top: 80px;
}
.template-menu ul {
  width: 204px;
}
.template-menu .mar-menu ul li .list {
  text-align: left;
  border-left-color: #106ebe;
  color: #106ebe;
  height: 36px;
  max-width: 188px;
  margin-bottom: 10px;
  border-left: 2px solid transparent;
  cursor: pointer;
}
.template-menu .mar-menu ul li .list:hover {
  background-color: #e5e7e9;
}
.template-menu .mar-menu ul li a:hover {
  color: #106ebe;
}
.template-menu .mar-menu ul li a {
  color: #1b1b1b;
  display: inline-block;
  height: 100%;
  padding: 7px 19px;
  width: 100%;
  line-height: 27px;
}
.template-menu .mar-menu ul li .list.acitve {
  border-left: 2px solid #106ebe;
  color: #106ebe;
}
.template-menu .mar-menu ul li a.acitve {
  color: #106ebe;
}
.my-account {
  position: relative;
  height: 100vh;
  margin: auto;
  background-color: #f7f7f7;
}
.my-account .content {
  max-width: 1168px;
  display: flex;
  margin: auto;
}
</style>
